import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";

// CORE
const TutorialAdmin = React.lazy(() =>
  import("./pages/Core/TutorialAdmin/Index.js")
);
const TutorialUser = React.lazy(() =>
  import("./pages/Core/TutorialUser/Index.js")
);

// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));
const ResetPassword = React.lazy(() =>
  import("./pages/Accounts/ForgetPassword/Index.js")
);

// UserPanel
const Panel = React.lazy(() => import("./pages/Panel/Home/Index.js"));
const ChatGPT = React.lazy(() => import("./pages/Panel/ChatGPT/Index.js"));

// Admin Panel
const Admin = React.lazy(() => import("./pages/Admin/Home/Index.js"));
const Company = React.lazy(() => import("./pages/Admin/Company/Index.js"));

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}

          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/zarejestruj-sie" element={<Register />} />
          <Route path="/przypomnij-haslo" element={<ResetPassword />} />
          <Route path="/tutorial" element={<TutorialUser />} />
          <Route path="/tutorial-admin" element={<TutorialAdmin />} />

          {authCtx.isLoggedIn && (
            <>
              <Route path="/" element={<Panel />} />
              <Route path="/chat-gpt" element={<ChatGPT />} />
              {/* ADMIN */}
              {authCtx.user && authCtx.user.is_admin && (
                <>
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/admin/:company_id" element={<Company />} />
                </>
              )}
              <Route path="/:user_id" element={<Panel />} />
            </>
          )}

          <Route path="*" element={<Navigate to="/zaloguj-sie" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
