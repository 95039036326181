export async function signIn(email, password) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
      },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };
    const response = await fetch(
      "https://backend.task4b.com/api/auth/login/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Something went wrong",
    };
  }
}

export async function sendEmailNewPassword(email) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.task4b.com/api/user/forget-password/?email=" + email,
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Password reset failed",
    };
  }
}

export async function SignUp(data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.task4b.com/api/auth/signup/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Something went wrong",
    };
  }
}

export async function getMyData(token) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.task4b.com/api/auth/me/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Cannot fetch user data, sign in again",
    };
  }
}

export async function todoFetchAll(token, user_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.task4b.com/api/project/" + user_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Fetching todo failed",
    };
  }
}

export async function todoCreateProject(token, _data) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(_data),
    };
    const response = await fetch(
      "https://backend.task4b.com/api/project/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Adding task list failed",
    };
  }
}

export async function askChatGPT(token, question, messages) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ question: question, messages: messages }),
    };
    const response = await fetch(
      "https://backend.task4b.com/api/openai/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "We can't serve your question.",
    };
  }
}

export async function uploadAttachement(token, file, todo_id) {
  try {
    let _formData = new FormData();
    _formData.append("file", file, file.name);
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: _formData,
    };
    const response = await fetch(
      "https://backend.task4b.com/api/attachement/" + todo_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Something went wrong...",
    };
  }
}

export async function deleteAttachement(token, todo_id) {
  try {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.task4b.com/api/attachement/" + todo_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Something went wrong...",
    };
  }
}

export async function updatePassword(token, data_in) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.task4b.com/api/user/password/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Something went wrong...",
    };
  }
}

export async function reSendNewPassword(email) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    };
    const response = await fetch(
      "https://backend.task4b.com/api/user/forget-password/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Something went wrong...",
    };
  }
}
